var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bp-arrow-input flex--row-center-center",class:{
    'bp-arrow-input--black': _vm.black,
    'bp-arrow-input--focused': _vm.inputFocus,
    'bp-arrow-input--loading': _vm.isLoading
  }},[_c('FormInput',{staticClass:"bp-arrow-input__input",class:{
      'bp-arrow-input__input--black': _vm.black,
      'bp-arrow-input__input--error': _vm.hasError
    },attrs:{"type":"email","noLabel":"","float":"","inputId":_vm.inputId,"value":_vm.value,"placeholder":_vm.placeholder,"autocomplete":"off","disabled":_vm.isLoading},on:{"input":_vm.handleInput},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (() => _vm.$emit('submit')).apply(null, arguments)}}}),_c('div',{staticClass:"bp-arrow-input__button flex--col-center-end",attrs:{"type":"submit","tabIndex":"0"},on:{"click":_vm.handleSubmitClick,"focus":() => (_vm.inputFocus = true),"blur":() => (_vm.inputFocus = false),"mouseover":() => (_vm.inputFocus = true),"mouseleave":() => (_vm.inputFocus = false)}},[_c('Arrow',{staticClass:"bp-arrow-input__arrow",class:{
        'bp-arrow-input__arrow--black': _vm.black
      }})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
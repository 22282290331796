<template>
  <div
    class="bp-arrow-input flex--row-center-center"
    :class="{
      'bp-arrow-input--black': black,
      'bp-arrow-input--focused': inputFocus,
      'bp-arrow-input--loading': isLoading
    }"
  >
    <FormInput
      class="bp-arrow-input__input"
      :class="{
        'bp-arrow-input__input--black': black,
        'bp-arrow-input__input--error': hasError
      }"
      type="email"
      noLabel
      float
      :inputId="inputId"
      :value="value"
      :placeholder="placeholder"
      autocomplete="off"
      @input="handleInput"
      @keyup.native.enter="() => $emit('submit')"
      :disabled="isLoading"
    />
    <div
      class="bp-arrow-input__button flex--col-center-end"
      @click="handleSubmitClick"
      @focus="() => (inputFocus = true)"
      @blur="() => (inputFocus = false)"
      @mouseover="() => (inputFocus = true)"
      @mouseleave="() => (inputFocus = false)"
      type="submit"
      tabIndex="0"
    >
      <!-- <Clock
        v-if="isLoading"
        class="bp-arrow-input__clock"
        :class="{
          'bp-arrow-input__clock--black': black
        }"
      /> -->
      <Arrow
        class="bp-arrow-input__arrow"
        :class="{
          'bp-arrow-input__arrow--black': black
        }"
      />
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/arrow.svg"
// import Clock from "@/assets/clock.svg"
import FormInput from "@/components/form/FormInput"

export default {
  props: {
    black: Boolean,
    hasError: Boolean,
    inputId: String,
    isLoading: Boolean,
    placeholder: String,
    typeClass: String,
    value: String
  },

  components: { Arrow, /* Clock, */ FormInput },

  data() {
    return {
      inputFocus: false
    }
  },

  methods: {
    handleInput(val) {
      this.debug.log("ArrowInput input", val)
      this.$emit("input", val)
    },

    handleSubmitClick(evt) {
      this.debug.log("ArrowInput submit", evt)
      this.$emit("submit")
    }
  }
}
</script>

<style lang="scss">
.bp-arrow-input {
  position: relative;
  border-bottom: 1.5px solid getcolour(bleach_white);

  &--black {
    border-bottom: 1.5px solid getcolour(bleach_black);
  }

  &--focused {
    .bp-arrow-input__arrow {
      right: -10px;
    }
  }

  &--loading {
    .bp-arrow-input__arrow {
      opacity: 0;
      transform: translateX(100%);
      //   path {
      //     animation: arrow-right-disappear 1.25s infinite ease-out;

      //     @media (prefers-reduced-motion: reduce) {
      //       animation: none;
      //     }
      //   }
    }
  }

  // .bp-input__container-field {
  //   padding-left: 0;
  //   border: none;
  //   border-bottom: 1px solid $bleach-white;
  // }

  input {
    background: transparent;
    color: inherit;
  }

  &__input {
    flex: 0 1 auto;

    div[data-lastpass-icon-root] {
      display: none;
    }

    input::placeholder {
      color: getcolour(bleach_white);
      opacity: 0.25;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: getcolour(bleach_white);
      -webkit-box-shadow: 0 0 0px 1000px getcolour(bleach_black) inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    input:disabled {
      opacity: 0.5;
    }

    &--black {
      input::placeholder {
        color: getcolour(bleach_black);
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus {
        -webkit-text-fill-color: getcolour(bleach_black);
        -webkit-box-shadow: 0 0 0px 1000px getcolour(bleach_black) inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }

  &__button {
    flex: 0 0 35px;
    cursor: pointer;
    width: 35px;
    height: 100%;
  }

  &__arrow,
  &__clock {
    transition: opacity 0.3s linear;
    height: 20px;
    width: 35px;
    fill: getcolour(bleach_white);
    stroke: getcolour(bleach_white);
    // top: 4px;
    transition: transform 0.3s ease-in, opacity 0.3s linear;

    path {
      vector-effect: non-scaling-stroke;
      stroke-width: 1px;
    }

    &--black {
      fill: getcolour(bleach_black);
      stroke: getcolour(bleach_black);
    }
  }

  &__clock {
    fill: none;

    &--black {
      fill: none;
    }
  }
}

// @keyframes arrow-right-disappear {
//   0% {
//     transform: translate3d(0, 0, 0);
//   }

//   100% {
//     transform: translate3d(240px, 0, 0);
//   }
// }
</style>
